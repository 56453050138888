<template>
  <div class="content block-el">
    <PageHeader v-if="group" v-bind:title="group.name" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-6 col-sm-12 col-lg-6">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <button
                        v-modal="{ target: 'create-contact' }"
                        class="btn btn-success btn-block"
                      >
                        <i class="icon dripicons-plus"></i>{{$t('generic-str.lbl-new-contact')}}
                      </button>
                      <button
                        class="btn btn-danger btn-remove border-radius-right"
                        @click="deleteAll"
                        :class="{ disabled: selectedContacts.length == 0 }"
                      >
                        <i class="icon dripicons-trash color-white"></i>{{$t('generic-str.delete')}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div v-if="fetched" class="responsive-table">
                <div
                  v-if="!group.contacts.data.length"
                  class="text-center table-placeholder"
                >
                  <i class="far fa-edit font-size-80"></i>
                  <h5 class="card-title m-t-20">{{$t('warnings.no-users')}}</h5>
                  <p>{{$t('warnings.register-user')}}</p>
                </div>
                <table v-else class="table">
                  <thead class="bg-light">
                    <tr>
                      <th class="w-60" scope="col">
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="selectAllContacts"
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                          />
                          <label
                            class="custom-control-label"
                            for="customCheck1"
                          ></label>
                        </div>
                      </th>
                      <th scope="col">{{$tc('generic-str.name', 1)}}</th>
                      <th scope="col">{{$tc('generic-str.lbl-number', 1)}}</th>
                      <th scope="col">Email</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(contact, i) in group.contacts.data"
                      :key="contact.id"
                    >
                      <td data-label="">
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="selectedContacts"
                            type="checkbox"
                            class="custom-control-input"
                            :id="'contact' + i"
                            :value="contact"
                          />
                          <label
                            class="custom-control-label"
                            :for="'contact' + i"
                          ></label>
                        </div>
                      </td>
                      <td data-label="Nome">{{ contact.first_name }}</td>
                      <td data-label="Número">{{ contact.mobile_number }}</td>
                      <td data-label="Email">{{ contact.email }}</td>
                      <td data-label="">
                        <div class="dropdown">
                          <a
                            role="button"
                            class="dropdown-action"
                            :to="'#menu-dropdown-' + i"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="icon dripicons-gear text-success"></i>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            :id="'menu-dropdown-' + i"
                            x-placement="bottom-end"
                            style=""
                          >
                            <a
                              class="dropdown-item emit-j-even"
                              v-modal="{
                                target: 'contact-modal',
                                data: contact,
                              }"
                              >{{$t('generic-str.edit')}}</a
                            >
                            <a
                              class="dropdown-item"
                              @click="destroy(contact.id)"
                              >{{$t('generic-str.remove')}}</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="static qt-block-ui" style="padding: 120px" />
              <br />
              <pagination :lastPage="pages" @change="fetch" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <contact-modal id="contact-modal"></contact-modal>
    <create-contact-modal
      id="create-contact"
      :group="group"
      @sent="fetch"
    ></create-contact-modal>
    <contact-modal
      id="contact-modal"
      :group="group"
      @sent="fetch"
    ></contact-modal>
    <!-- <div v-else class="relative qt-block-ui" style="padding: 120px" /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import CreateContactModal from '@/components/contacts/CreateContactModal.vue';
import ContactService from '@/services/contact.service';
import ContactModal from '@/components/contacts/ContactModal.vue';
import Pagination from '@/components/Pagination.vue';
import Swal from 'sweetalert2';

export default {
  name: 'GroupContacts',
  components: {
    PageHeader,
    Pagination,
    CreateContactModal,
    ContactModal,
  },
  data() {
    return {
      fetched: false,
      title: '',
      group: null,
      form: {
        page: 1,
      },
      pages: 1,
      isSending: false,
      selectedContactsAdd: [],
      contacts: [],
      selectedContacts: [],
    };
  },
  computed: {
    selectAllContacts: {
      get() {
        if (this.group.contacts.data) {
          return (
            this.selectedContacts.length === this.group.contacts.data.length
          );
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          const contacts = this.group.contacts.data;

          contacts.forEach((contact) => {
            selected.push(contact);
          });
        }

        this.selectedContacts = selected;
      },
    },
  },
  mounted() {
    this.$root.$on('contacts.addonsend', this.selectedContactsEvent);
  },
  created() {
    /* this.group = this.$route.params.group;
    this.title = `${this.group.name}`; */
    this.fetch();
  },
  methods: {
    selectedContactsEvent(param) {
      param.selectedContacts.forEach((contact) => {
        if (!this.selectedContactsAdd.includes(contact.id)) {
          contact.groups.push(this.group);
          this.contacts.push(contact);
          this.selectedContactsAdd.push(contact.id);

          this.fetched = false;
          ContactService.editList(this.selectedContactsAdd).then(
            () => {
              this.hide();
              this.isSending = false;
              this.fetch();
            },
            (error) => {
              this.fetch();
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
    fetch(page = 1) {
      this.form.page = page;
      this.fetched = false;
      ContactService.getGroup(this.$route.params.id, this.form).then(
        (group) => {
          this.group = group;
          this.fetched = true;
          this.pages = group.contacts.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    destroy(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('contact-list-component.group-contacts.remove'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('contact-list-component.yes'),
      }).then((result) => {
        if (result.isConfirmed) {
          ContactService.deleteGroupContact(this.$route.params.id, id).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, this.$t('contact-list-component.deleted'), 'success');
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    deleteAll() {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('contact-list-component.group-contacts.remove-2'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('contact-list-component.yes'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          const ids = [];
          this.selectedContacts.forEach((item) => {
            ids.push(item.id);
          });
          ContactService.deleteGroupContacts(
            { id: ids },
            this.$route.params.id,
          ).then(
            () => {
              // this.hide();
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('contact-list-component.group-contacts.removed'),
                type: 'success',
              });
              this.fetch();
              this.selectedContacts = [];
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
    back() {
      this.$router.push({ name: 'ContactsGroups' });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-control-label {
  &::after,
  &::before {
    top: -0.3rem;
  }
}
.btn i {
  line-height: 1;
  color: inherit;
}
</style>
